const WEBCLASS_COUNTDOWN_INTERVAL = 600;

let leaveModalWaitTime = 1000;

function onLeaveModal()
{
    $(document).one('mouseleave', (e) => {
        if (e.clientY < 0) {
            $('#modal-sign-up').modal('show');
            $('#modal-make-appointment:not(.hidden)').modal('show');

            setTimeout(() => {
                onLeaveModal();
            }, leaveModalWaitTime);
        }
    });

    // Wait 2 minutes before showing an exit-modal again
    $('#modal-sign-up, #modal-make-appointment').on('show.bs.modal', () => {
        leaveModalWaitTime = 1000 * 120;
    })
};

function padNumber(number, digits)
{
    let paddedNumber = number + "";

    while (paddedNumber.length < digits) {
        paddedNumber = "0" + paddedNumber;
    }

    return paddedNumber;
}

function updateWebclassStartTimes()
{
    $('.js-webclass-start-time').each(function (index, element) {
        let startTime = new Date();
        startTime.setTime(Math.ceil(new Date().getTime() / 1000 / 600) * 1000 * 600);
        element.innerHTML = `${padNumber(startTime.getHours(), 2)}:${padNumber(startTime.getMinutes(), 2)}`;

        setTimeout(() => {
            updateWebclassStartTimes();
        }, startTime - new Date() + 10);
    });
}

onLeaveModal();
updateWebclassStartTimes();

// fixed modulo
const mod = function (number, modulo) {
    return ((number % modulo) + modulo) % modulo;
}

const getCountdownDuration = global.getCountdownDuration = function () {
    return mod(-new Date().getTime()/1000, WEBCLASS_COUNTDOWN_INTERVAL);
}
